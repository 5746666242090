<script>

  import "@vueform/multiselect/themes/default.css";

  //import Swal from "sweetalert2";

  import Layout from "../../../layouts/main.vue";
  import appConfig from "../../../../app.config";
  import PageHeader from "@/components/page-header";
  //import axios from 'axios';
  //import animationData from "@/components/widgets/msoeawqm.json";
  import ordersTable from "@/components/widgets/store/ordersTable";
  import ordersUsrTable from "@/components/widgets/store/ordersUsrTable";
  export default {
    page: {
      title: "Ordini",
      meta: [{
        name: "description",
        content: appConfig.description,
      }, ],
    },
    data() {
      return {
        datastore: this.$store.state,
        title: "Gestione Ordini/preventivi",
        items: [{
            text: "Ordini/preventivi",
            href: "/",
          },
          {
            text: "Elenco",
            active: true,
          },
        ],

      };
    },
    components: {
      Layout,
      PageHeader,
      ordersTable,
      ordersUsrTable,
    },
   
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <template v-if="datastore.auth">
          <template v-if="datastore.auth.currentUser.registryType=='main'">
            <ordersTable   />
          </template>
          <template v-else>
             <ordersUsrTable   />
          </template>
        </template>
      </div>
    </div>
  </Layout>
</template>